import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import type { Entity } from "../../lib/types";
import { api } from "../../lib/api";
import { useToast } from "../../hooks/use-toast";

interface EntityDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  entity: Entity | null;
  onSave: () => void;
}

export const EntityDialog = ({
  open,
  onOpenChange,
  entity,
  onSave,
}: EntityDialogProps) => {
  const { toast } = useToast();
  const form = useForm({
    defaultValues: {
      name: "",
      type: "pharmacy",
      branch_id: "",
      client_id: "",
    },
  });

  useEffect(() => {
    if (entity) {
      form.reset({
        name: entity.name,
        type: entity.type,
        branch_id: entity.branch_id?.toString() || "",
        client_id: entity.client_id?.toString() || "",
      });
    } else {
      form.reset({
        name: "",
        type: "pharmacy",
        branch_id: "",
        client_id: "",
      });
    }
  }, [entity]);

  const onSubmit = async (data: any) => {
    try {
      if (entity) {
        await api.updateEntity(entity.id, data);
        toast({
          title: "Entity Updated",
          description: `${data.name} details updated`,
          variant: "default",
        });
      } else {
        await api.createEntity(data);
        toast({
          title: "Entity Created",
          description: `${data.name} details created`,
          variant: "default",
        });
      }
      onSave();
      onOpenChange(false);
    } catch (error) {
      console.error("Failed to save entity:", error);
      toast({
        title: "Failed to save entity",
        description: `${error}`,
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{entity ? "Edit Entity" : "Create Entity"}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    disabled={!!entity}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="pharmacy">Pharmacy</SelectItem>
                      <SelectItem value="entity">Entity</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="branch_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Branch ID</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} min={1} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="client_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Client ID</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} min={1} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">Save</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
