import type { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../lib/authContext";
import { Sidebar } from "../layout/Sidebar";
import { KnockWrapper } from "../notifications/KnockProvider";
import { Toaster } from "../ui/toaster";

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex min-h-screen bg-gray-100 dark:bg-gray-900">
      <div className="fixed h-screen bg-gray-100 dark:bg-gray-900">
        <Sidebar />
      </div>
      <div className="flex-1">
        <header className="bg-white shadow dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
              Dashboard
            </h1>
            <KnockWrapper />
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <Toaster />
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};
