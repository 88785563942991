import * as React from "react";
import { cities, modes, providers } from "../../services/mockDataService";
import { CustomSelect } from "./custom-select";
import { DateInput } from "./dateInput";

interface FiltersProps {
	selectedCity: string;
	setSelectedCity: (city: string) => void;
	selectedProvider: string;
	setSelectedProvider: (provider: string) => void;
	selectedMode: string;
	setSelectedMode: (mode: string) => void;
	startDate?: string;
	setStartDate?: (date: string) => void;
	endDate?: string;
	setEndDate?: (date: string) => void;
	showDateFilter?: boolean;
}

export function Filters({
	selectedCity,
	setSelectedCity,
	selectedProvider,
	setSelectedProvider,
	selectedMode,
	setSelectedMode,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	showDateFilter = true,
}: FiltersProps) {
	return (
		<div className="flex items-center space-x-4">
			<CustomSelect
				options={cities}
				value={selectedCity}
				onChange={setSelectedCity}
				placeholder="Select City"
				className="w-[200px]"
			/>
			<CustomSelect
				options={providers}
				value={selectedProvider}
				onChange={setSelectedProvider}
				placeholder="Select Provider"
				className="w-[200px]"
			/>
			<CustomSelect
				options={modes}
				value={selectedMode}
				onChange={setSelectedMode}
				placeholder="Select Mode"
				className="w-[200px]"
			/>
			{showDateFilter &&
				startDate !== undefined &&
				endDate !== undefined &&
				setStartDate &&
				setEndDate && (
					<div className="flex items-center space-x-2">
						<DateInput value={startDate} onChange={setStartDate} />
						<span>-</span>
						<DateInput value={endDate} onChange={setEndDate} />
					</div>
				)}
		</div>
	);
}
