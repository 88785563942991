import { Input } from "./input";

const DateInput: React.FC<{
	value: string;
	onChange: (date: string) => void;
}> = ({ value, onChange }) => (
	<Input
		type="date"
		className="w-40"
		value={value}
		onChange={(e) => {
			const newDate = e.target.value;
			if (!Number.isNaN(new Date(newDate).getTime())) {
				const formattedDate = new Date(newDate).toISOString().split("T")[0];
				onChange(formattedDate);
			}
		}}
	/>
);

export { DateInput };
