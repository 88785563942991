import type { Order, Pharmacy } from "../lib/types";

// Add new interfaces for dashboard statistics
interface OrderStats {
	status: string;
	count: string;
}

interface PharmacyStats {
	pharmacy: string;
	"1D": number;
	"2D": number;
	"5D+": number;
	// Additional fields for Hyperlocal mode
	"10 mins"?: number;
	"11-20 mins"?: number;
	"21-30 mins"?: number;
	"31-40 mins"?: number;
	"41-50 mins"?: number;
	"51-60 mins"?: number;
}

interface StatusStats {
	status: string;
	"1D": number;
	"2D": number;
	total_orders: number;
}

interface OrderFilters {
	group_by?: string;
	start_date?: string;
	end_date?: string;
	city?: string;
	provider?: string;
	mode?: string;
}

const mockPharmacies: Pharmacy[] = [
	{
		id: "1",
		name: "Central Pharmacy",
		location: {
			latitude: 40.7128,
			longitude: -74.006,
		},
		fulfillmentPercentage: 95,
		currentBills: 50,
		maxBills: 100,
		fulfillmentSpeed: 85,
		remainingOperatingHours: 8,
		totalOperatingHours: 12,
		slaAdherence: 98,
		inventory: {
			Aspirin: 100,
			Bandages: 200,
			Antibiotics: 50,
			"Cough Syrup": 75,
			Paracetamol: 150,
			"Allergy Medicine": 30,
		},
		procurementTime: 4,
		distance: 0,
	},
	{
		id: "2",
		name: "Westside Drugs",
		location: {
			latitude: 40.7589,
			longitude: -73.9851,
		},
		fulfillmentPercentage: 88,
		currentBills: 40,
		maxBills: 80,
		fulfillmentSpeed: 80,
		remainingOperatingHours: 6,
		totalOperatingHours: 10,
		slaAdherence: 95,
		inventory: {
			Aspirin: 75,
			Bandages: 150,
			Antibiotics: 25,
			"Cough Syrup": 100,
			Paracetamol: 200,
			"Allergy Medicine": 10,
		},
		procurementTime: 6,
		distance: 0,
	},
	{
		id: "3",
		name: "Eastside Meds",
		location: {
			latitude: 40.7549,
			longitude: -73.984,
		},
		fulfillmentPercentage: 92,
		currentBills: 45,
		maxBills: 90,
		fulfillmentSpeed: 82,
		remainingOperatingHours: 7,
		totalOperatingHours: 11,
		slaAdherence: 97,
		inventory: {
			Aspirin: 90,
			Bandages: 180,
			Antibiotics: 40,
			"Cough Syrup": 60,
			Paracetamol: 100,
			"Allergy Medicine": 50,
		},
		procurementTime: 5,
		distance: 0,
	},
];

// Add mock data for order statistics
const mockOrderStats: OrderStats[] = [
	{ status: "Pending", count: "45" },
	{ status: "Accepted", count: "30" },
	{ status: "Dispatched", count: "25" },
	{ status: "Delivered", count: "80" },
	{ status: "Rejected", count: "10" },
	// { status: "Billing", count: "30" },
	// { status: "Billed", count: "25" },
	// { status: "Transferred", count: "80" },
	// { status: "RTO", count: "10" },
];

// Update mockPharmacyStats with city and provider information
const mockPharmacyStats: (PharmacyStats & {
	city: string;
	provider: string;
})[] = [
	{
		pharmacy: "MediSYS",
		city: "hyderabad",
		provider: "medibuddy",
		"1D": 30,
		"2D": 20,
		"5D+": 15,
		"10 mins": 10,
		"11-20 mins": 15,
		"21-30 mins": 20,
		"31-40 mins": 12,
		"41-50 mins": 8,
		"51-60 mins": 5,
	},
	{
		pharmacy: "FCP",
		city: "chennai",
		provider: "pazcare",
		"1D": 20,
		"2D": 15,
		"5D+": 10,
		"10 mins": 8,
		"11-20 mins": 12,
		"21-30 mins": 15,
		"31-40 mins": 10,
		"41-50 mins": 5,
		"51-60 mins": 3,
	},
	{
		pharmacy: "SSP",
		city: "kolkata",
		provider: "flipkart",
		"1D": 35,
		"2D": 20,
		"5D+": 25,
		"10 mins": 12,
		"11-20 mins": 18,
		"21-30 mins": 25,
		"31-40 mins": 15,
		"41-50 mins": 10,
		"51-60 mins": 7,
	},
];

const mockStatusStats: StatusStats[] = [
	{ status: "Pending", "1D": 20, "2D": 15, total_orders: 45 },
	{ status: "Accepted", "1D": 15, "2D": 10, total_orders: 30 },
	{ status: "Dispatched", "1D": 12, "2D": 8, total_orders: 25 },
	{ status: "Delivered", "1D": 40, "2D": 30, total_orders: 80 },
	{ status: "Rejected", "1D": 5, "2D": 3, total_orders: 10 },
];

export const mockDataService = {
	getPharmacies: (): Promise<Pharmacy[]> => {
		return Promise.resolve(mockPharmacies);
	},

	getOrders: async (): Promise<Order[]> => {
		return [
			// ... mock order data
		];
	},

	getPharmacyById: async (id: string): Promise<Pharmacy | undefined> => {
		const pharmacies = await mockDataService.getPharmacies();
		return pharmacies.find((p) => p.id === id);
	},

	calculateDistance: (
		lat1: number,
		lon1: number,
		lat2: number,
		lon2: number,
	): number => {
		// Convert degrees to radians
		const toRad = (x: number): number => (x * Math.PI) / 180;

		const R = 6371; // Earth's radius in km
		const dLat = toRad(lat2 - lat1);
		const dLon = toRad(lon2 - lon1);

		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(toRad(lat1)) *
				Math.cos(toRad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		// Round to 1 decimal place
		return Math.round(R * c * 10) / 10;
	},

	getGroupedOrders: async (filters: OrderFilters): Promise<OrderStats[]> => {
		// Apply filters to mock data
		let filteredStats = [...mockOrderStats];

		if (filters.city || filters.provider || filters.mode) {
			// Simulate filtering by reducing counts
			filteredStats = filteredStats.map((stat) => ({
				...stat,
				count: Math.floor(Number.parseInt(stat.count) * 0.7).toString(),
			}));
		}

		return filteredStats;
	},

	getPharmacyStats: async (filters: OrderFilters): Promise<PharmacyStats[]> => {
		let filteredStats = [...mockPharmacyStats];

		if (filters.city && filters.city !== "all") {
			filteredStats = filteredStats.filter(
				(stat) => stat.city === filters.city,
			);
		}

		if (filters.provider && filters.provider !== "all") {
			filteredStats = filteredStats.filter(
				(stat) => stat.provider === filters.provider,
			);
		}

		// Remove the extra properties before returning
		return filteredStats.map(({ city, provider, ...rest }) => rest);
	},

	getStatusStats: async (filters: OrderFilters): Promise<StatusStats[]> => {
		let filteredStats = [...mockStatusStats];

		if (filters.city || filters.provider || filters.mode) {
			// Simulate filtering by reducing counts
			filteredStats = filteredStats.map((stat) => ({
				...stat,
				"1D": Math.floor(stat["1D"] * 0.7),
				"2D": Math.floor(stat["2D"] * 0.7),
				total_orders: Math.floor(stat.total_orders * 0.7),
			}));
		}

		return filteredStats;
	},
};

export const COMMANDS = [
	{
		id: "track-order",
		name: "Track an order - Coming Soon",
		shortcut: ["T"],
		action: (navigate: (path: string) => void) => {
			console.log("Track order");
		},
	},
	{
		id: "update-order",
		name: "Update an order - Coming Soon",
		shortcut: ["U"],
		action: (navigate: (path: string) => void) => {
			console.log("Update order");
		},
	},
	{
		id: "update-awb",
		name: "Update AWB - Coming Soon",
		shortcut: ["A"],
		action: (navigate: (path: string) => void) => {
			console.log("Update AWB");
		},
	},
	{
		id: "today-orders",
		name: "Show today's orders",
		shortcut: ["1"],
		action: (navigate: (path: string) => void) => {
			const today = new Date().toISOString().split("T")[0];
			navigate(`/orders?startDate=${today}&endDate=${today}`);
		},
	},
	{
		id: "week-orders",
		name: "Show last 7 days orders",
		shortcut: ["7"],
		action: (navigate: (path: string) => void) => {
			const end = new Date().toISOString().split("T")[0];
			const start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
				.toISOString()
				.split("T")[0];
			navigate(`/orders?startDate=${start}&endDate=${end}`);
		},
	},
	{
		id: "fifteen-days-orders",
		name: "Show last 15 days orders",
		shortcut: ["15"],
		action: (navigate: (path: string) => void) => {
			const end = new Date().toISOString().split("T")[0];
			const start = new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)
				.toISOString()
				.split("T")[0];
			navigate(`/orders?startDate=${start}&endDate=${end}`);
		},
	},
	{
		id: "month-orders",
		name: "Show last month orders",
		shortcut: ["M"],
		action: (navigate: (path: string) => void) => {
			const end = new Date().toISOString().split("T")[0];
			const start = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
				.toISOString()
				.split("T")[0];
			navigate(`/orders?startDate=${start}&endDate=${end}`);
		},
	},
];

export const cities = [
	{ value: "all", label: "All Cities" },
	{ value: "hyderabad", label: "Hyderabad" },
	{ value: "chennai", label: "Chennai" },
	{ value: "kolkata", label: "Kolkata" },
];

export const providers = [
	{ value: "all", label: "All Providers" },
	{ value: "medibuddy", label: "MediBuddy" },
	{ value: "pazcare", label: "PazCare" },
	{ value: "flipkart", label: "Flipkart Health" },
];

export const modes = [
	{ value: "all", label: "All (excl. Hyperlocal)" },
	{ value: "Hyperlocal", label: "Hyperlocal" },
	{ value: "SDD", label: "SDD" },
	{ value: "NDD", label: "NDD" },
	{ value: "Store pick-up", label: "Store pick-up" },
];
