import hyperlocalIcon from "../icons/hyperlocal.svg";
import nddIcon from "../icons/ndd.svg";
import sddIcon from "../icons/sdd.svg";

export const statusColors: Record<string, string> = {
	Pending:
		"bg-yellow-100 hover:bg-yellow-200 text-yellow-800 dark:bg-yellow-900 dark:hover:bg-yellow-800 dark:text-yellow-100",
	Accepted:
		"bg-green-100 hover:bg-green-200 text-green-800 dark:bg-green-900 dark:hover:bg-green-800 dark:text-green-100",
	Billed:
		"bg-blue-100 hover:bg-blue-200 text-blue-800 dark:bg-blue-900 dark:hover:bg-blue-800 dark:text-blue-100",
	Transit:
		"bg-indigo-100 hover:bg-indigo-200 text-indigo-800 dark:bg-indigo-900 dark:hover:bg-indigo-800 dark:text-indigo-100",
	Dispatched:
		"bg-purple-100 hover:bg-purple-200 text-purple-800 dark:bg-purple-900 dark:hover:bg-purple-800 dark:text-purple-100",
	Delivered:
		"bg-green-100 hover:bg-green-200 text-green-800 dark:bg-green-900 dark:hover:bg-green-800 dark:text-green-100",
	Transferred:
		"bg-gray-100 hover:bg-gray-200 text-gray-800 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-100",
	Rejected:
		"bg-red-100 hover:bg-red-200 text-red-800 dark:bg-red-900 dark:hover:bg-red-800 dark:text-red-100",
	Cancelled:
		"bg-gray-100 hover:bg-gray-200 text-gray-800 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-100",
	RTO: "bg-gray-100 hover:bg-gray-200 text-gray-800 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-100",
};

const orderStatus = Object.keys(statusColors);

const trackOrderStatus = [
	"Pending",
	"Accepted",
	"Billed",
	"Dispatched",
	"Delivered",
];

export enum orderStatusEnum {
	Pending = "Pending",
	Accepted = "Accepted",
	Rejected = "Rejected",
	Billed = "Billed",
	Billing = "Billing",
	Verified = "Verified",
	Cancelled = "Cancelled",
	Dispatched = "Dispatched",
	InDelivery = "In Delivery",
	Transit = "Transit",
	Delivered = "Delivered",
	RTO = "RTO",
	Transferred = "Transferred",
	TransferFailed = "Transfer Failed",
}

const modeIcons: {
	[key: string]: { icon: string; bgColor: string; color: string };
} = {
	hyperlocal: {
		icon: hyperlocalIcon,
		bgColor: "bg-hyperlocalBg",
		color: "text-hyperlocalText",
	},
	ndd: {
		icon: nddIcon,
		bgColor: "bg-nddBg",
		color: "text-nddText",
	},
	sdd: {
		icon: sddIcon,
		bgColor: "bg-sddBg",
		color: "text-sddText",
	},
};

const entityTypeColors: Record<string, string> = {
	pharmacy: "bg-hyperlocalBg text-hyperlocalText",
	entity: "bg-nddBg text-nddText",
};

export { orderStatus, trackOrderStatus, modeIcons, entityTypeColors };
