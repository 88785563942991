import { useEffect, useState, useRef } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Button } from "../ui/button";
import type { Entity, Pincode } from "../../lib/types";
import { api } from "../../lib/api";
import { Input } from "../ui/input";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { useAuth } from "../../lib/authContext";
import { useToast } from "../../hooks/use-toast";

interface PincodeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  entity: Entity | null;
}

export const PincodeDialog = ({
  open,
  onOpenChange,
  entity,
}: PincodeDialogProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPincodes, setSelectedPincodes] = useState<Pincode[]>([]);
  const [availablePincodes, setAvailablePincodes] = useState<Pincode[]>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { accessLevel } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    if (entity && open) {
      fetchEntityPincodes();
      fetchAvailablePincodes();
    }
  }, [entity, open]);

  const fetchEntityPincodes = async () => {
    if (!entity) return;
    try {
      const response = await api.getEntityPincodes(entity.id);
      setSelectedPincodes(response.results);
    } catch (error) {
      console.error("Failed to fetch entity pincodes:", error);
      toast({
        title: "Failed to fetch entity pincodes",
        description: `${error}`,
        variant: "destructive",
      });
    }
  };

  const fetchAvailablePincodes = async () => {
    if (searchTerm.length < 3) return;
    try {
      const response = await api.searchPincodes(
        searchTerm,
        Object.entries(accessLevel?.access_level || {})
          .map(([key, values]) => `${key}:${values.join(",")}`)
          .join("|"),
      );
      setAvailablePincodes(response.results);
    } catch (error) {
      console.error("Failed to fetch pincodes:", error);
      toast({
        title: "Failed to fetch pincodes",
        description: `${error}`,
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchAvailablePincodes();
    } else {
      setAvailablePincodes([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (availablePincodes.length > 0) {
      setPopoverOpen(true);
      inputRef.current?.focus();
    } else {
      setPopoverOpen(false);
    }
  }, [availablePincodes]);

  useEffect(() => {
    if (!open) {
      setSearchTerm("");
      setSelectedPincodes([]);
    }
  }, [open]);

  const handleSave = async () => {
    if (!entity) return;
    try {
      await api.updateEntityPincodes(
        entity.id,
        selectedPincodes.map(p => p.id),
      );
      onOpenChange(false);
      toast({
        title: "Pincodes Updated",
        description: `${entity.name} pincodes updated`,
      });
    } catch (error) {
      console.error("Failed to update entity pincodes:", error);
      toast({
        title: "Failed to update entity pincodes",
        description: `${error}`,
        variant: "destructive",
      });
    }
  };

  const handleOutsideClick = (event: MouseEvent) => {
    const popover = popoverRef.current;
    if (popover && !popover.contains(event.target as Node)) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handlePincodeSelect = (pincode: Pincode) => {
    if (!selectedPincodes.find(p => p.id === pincode.id)) {
      setSelectedPincodes([...selectedPincodes, pincode]);
      setAvailablePincodes(availablePincodes.filter(p => p.id !== pincode.id));
    }
    setPopoverOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Manage Pincodes - {entity?.name}</DialogTitle>
        </DialogHeader>

        <div className="space-y-2">
          <h4 className="font-medium">Selected Pincodes:</h4>
          <div className="flex flex-wrap gap-2">
            {selectedPincodes.map(pincode => (
              <div
                key={pincode.id}
                className="flex items-center gap-2 bg-secondary p-2 rounded-md"
              >
                {pincode.pincode}
                <button
                  type="button"
                  onClick={() => {
                    setSelectedPincodes(
                      selectedPincodes.filter(p => p.id !== pincode.id),
                    );
                  }}
                  className="text-destructive"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
          <PopoverTrigger className="w-full">
            <Input
              ref={inputRef}
              placeholder="Search pincodes..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="focus:visible-ring-0"
            />
          </PopoverTrigger>
          <div ref={popoverRef}>
            <PopoverContent className="dark:bg-gray-800 dark:border-gray-700">
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                {availablePincodes.length === 0 ? (
                  <div>No results found.</div>
                ) : (
                  <ul>
                    {availablePincodes?.map(pincode => (
                      <li
                        key={pincode.id}
                        onClick={() => handlePincodeSelect(pincode)}
                        className="cursor-pointer p-2 hover:bg-gray-200"
                      >
                        {pincode.pincode}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </PopoverContent>
          </div>
        </Popover>

        <Button onClick={handleSave}>Save Changes</Button>
      </DialogContent>
    </Dialog>
  );
};
