import {
	KnockFeedProvider,
	KnockProvider as KnockRoot,
	NotificationFeedPopover,
	NotificationIconButton,
} from "@knocklabs/react";
import { type ReactNode, useRef, useState } from "react";
import { useAuth } from "../../lib/authContext";

import "@knocklabs/react/dist/index.css";

interface KnockWrapperProps {
	children: ReactNode;
}

export const KnockWrapper = () => {
	const { user } = useAuth();
	const [isVisible, setIsVisible] = useState(false);
	const notifButtonRef = useRef(null);

	return (
		<KnockRoot
			apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY ?? ""}
			userId={user?.id ?? ""}
		>
			<KnockFeedProvider
				feedId={process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID ?? ""}
			>
				<>
					<NotificationIconButton
						ref={notifButtonRef}
						onClick={() => setIsVisible(!isVisible)}
					/>
					<NotificationFeedPopover
						buttonRef={notifButtonRef}
						isVisible={isVisible}
						onClose={() => setIsVisible(false)}
					/>
				</>
			</KnockFeedProvider>
		</KnockRoot>
	);
};
