import React, { useEffect, useState } from "react";
import api from "../../lib/api";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";
import { Input } from "../ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Edit, EditIcon, PlusIcon, TrashIcon } from "lucide-react";
import { useToast } from "../../hooks/use-toast";

interface ManageAccessPopoverProps {
  userId: string;
  onClose: () => void;
}

const ManageAccessPopover: React.FC<ManageAccessPopoverProps> = ({
  userId,
  onClose,
}) => {
  const [accessLevels, setAccessLevels] = useState<any[]>([]);
  const [accessId, setAccessId] = useState(0);
  const [newAccessType, setNewAccessType] = useState("");
  const [newAccessTypeId, setNewAccessTypeId] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const loadAccessLevels = async () => {
      const levels = await api.getAccessLevels(userId);
      setAccessLevels(levels.results);
    };
    loadAccessLevels();
  }, [userId]);

  const handleAddOrUpdate = async () => {
    if (newAccessTypeId <= 0) return;
    const existingLevel = accessLevels.find(level => level.id === accessId);

    if (existingLevel) {
      await api.updateAccess({
        id: accessId,
        access_type: newAccessType,
        access_type_id: newAccessTypeId,
      });
      toast({
        title: "Access Level Updated",
        description: `New Access: ${newAccessType} (${newAccessTypeId})`,
      });
      setAccessLevels(
        accessLevels.map(level =>
          level.id === accessId
            ? {
                ...level,
                access_type: newAccessType,
                access_type_id: newAccessTypeId,
              }
            : level,
        ),
      );
    } else {
      await api.createAccess({
        user_id: userId,
        access_type_id: newAccessTypeId,
        access_type: newAccessType,
      });
      toast({
        title: "Access Level Created",
        description: `New Access: ${newAccessType} (${newAccessTypeId})`,
      });
      setAccessLevels([
        ...accessLevels,
        { access_type: newAccessType, access_type_id: newAccessTypeId },
      ]);
    }
    // Reset form
    setNewAccessType("");
    setNewAccessTypeId(0);
    setAccessId(0);
    setIsEditing(false);
  };

  const handleEdit = async (id: number) => {
    const existingLevel = accessLevels.find(level => level.id === id);
    if (existingLevel) {
      setAccessId(existingLevel.id);
      setNewAccessType(existingLevel.access_type);
      setNewAccessTypeId(existingLevel.access_type_id);
      setIsEditing(true);
    }
  };

  const handleCancel = () => {
    setAccessId(0);
    setNewAccessType("");
    setNewAccessTypeId(0);
    setIsEditing(false);
  };

  const handleDelete = async (id: number) => {
    await api.deleteAccess(id);
    toast({
      title: "Access Level Deleted",
      description: `Deleted Access: ${
        accessLevels.find(level => level.id === id)?.access_type
      } (${accessLevels.find(level => level.id === id)?.access_type_id})`,
    });
    setAccessLevels(accessLevels.filter(level => level.id !== id));
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>
          Manage Access Levels
          <Button
            onClick={() => setIsEditing(true)}
            className="ml-2 bg-white text-black hover:text-white border dark:hover:bg-gray-600 border-gray-300 dark:bg-black dark:text-white"
          >
            <PlusIcon className="w-4 h-4" />
          </Button>
        </DialogTitle>
        {accessLevels.length > 0 ? (
          <div className="flex flex-col items-center">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Type</TableHead>
                  <TableHead>Type ID</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {accessLevels.map(level => (
                  <TableRow key={level.id}>
                    <TableCell>{level.access_type}</TableCell>
                    <TableCell>{level.access_type_id}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleEdit(level.id)}
                        className="bg-white text-black hover:text-white dark:hover:bg-gray-600 border hover:bg-gray-100 border-gray-300 dark:bg-black dark:text-white"
                      >
                        <EditIcon className="w-4 h-4 mr-2" /> Edit
                      </Button>
                      <Button
                        onClick={() => handleDelete(level.id)}
                        className="ml-2 bg-red-500 text-white border border-gray-300 dark:bg-red-800 dark:text-white"
                      >
                        <TrashIcon className="w-4 h-4 mr-2" /> Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="text-center">No access Levels Found</div>
        )}

        {isEditing && (
          <div className="flex flex-col items-center mt-4">
            <div className="mb-2 flex justify-between w-full space-x-2">
              <Select
                value={newAccessType}
                onValueChange={value => setNewAccessType(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="state">State</SelectItem>
                  <SelectItem value="city">City</SelectItem>
                  <SelectItem value="region">Region</SelectItem>
                </SelectContent>
              </Select>
              <Input
                type="number"
                value={newAccessTypeId}
                onChange={e => setNewAccessTypeId(Number(e.target.value))}
                placeholder="Access Type ID"
                className="mb-2"
              />
            </div>
            <div className="flex items-center">
              <Button
                onClick={handleAddOrUpdate}
                className="mb-2 bg-white text-black hover:text-white border dark:hover:bg-gray-600 border-gray-300 dark:bg-black dark:text-white"
              >
                <PlusIcon className="w-4 h-4 mr-2" />
                {accessId ? "Update" : "Add"}
              </Button>
              <Button
                onClick={handleCancel}
                className="mb-2 ml-2 bg-red-500 text-white border border-gray-300 dark:bg-red-800 dark:text-white"
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ManageAccessPopover;
