import { Search } from "lucide-react";
import type React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../lib/api";
import type {
	OrderStats,
	PharmacyStats,
	PharmacyStatsResponse,
	StatusStats,
} from "../../lib/types";
import { Filters } from "../ui/Filters";
import { StatusCard } from "../ui/StatusCard";
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip";

export const Dashboard: React.FC = () => {
	const [orders, setOrders] = useState<OrderStats[]>([]);
	const [pharmacyStats, setPharmacyStats] = useState<PharmacyStats[]>([]);
	const [statusStats, setStatusStats] = useState<StatusStats[]>([]);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [selectedCity, setSelectedCity] = useState<string>("all");
	const [selectedProvider, setSelectedProvider] = useState<string>("all");
	const [selectedMode, setSelectedMode] = useState<string>("all");
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [isCommandOpen, setIsCommandOpen] = useState(false);

	const selectedStatus = searchParams.get("status");
	const [statusFilter, setStatusFilter] = useState<string>(
		selectedStatus ?? "",
	);
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		const fetchData = async () => {
			const [orders_data, pharmacy_stats, status_stats] = await Promise.all([
				api.getGroupedOrders({
					group_by: "status",
					city: selectedCity !== "all" ? selectedCity : undefined,
					provider: selectedProvider !== "all" ? selectedProvider : undefined,
					mode: selectedMode !== "all" ? selectedMode : undefined,
					...(startDate !== "" ? { start_date: startDate } : {}),
					...(endDate !== "" ? { end_date: endDate } : {}),
				}),
				api.getPharmacyStats({
					city: selectedCity !== "all" ? selectedCity : undefined,
					provider: selectedProvider !== "all" ? selectedProvider : undefined,
					mode: selectedMode !== "all" ? selectedMode : undefined,
				}),
				api.getStatusStats({
					city: selectedCity !== "all" ? selectedCity : undefined,
					provider: selectedProvider !== "all" ? selectedProvider : undefined,
					mode: selectedMode !== "all" ? selectedMode : undefined,
				}),
			]);

			// Transform pharmacy_stats to ensure all required fields are present
			const transformedPharmacyStats: PharmacyStats[] = (
				pharmacy_stats as PharmacyStatsResponse[]
			).map((stat) => ({
				pharmacy: stat.pharmacy,
				"1D": stat["1D"] ?? 0,
				"2D": stat["2D"] ?? 0,
				"5D+": stat["5D+"] ?? 0,
				"10 mins": stat["10 mins"] ?? 0,
				"11-20 mins": stat["11-20 mins"] ?? 0,
				"21-30 mins": stat["21-30 mins"] ?? 0,
				"31-40 mins": stat["31-40 mins"] ?? 0,
				"41-50 mins": stat["41-50 mins"] ?? 0,
				"51-60 mins": stat["51-60 mins"] ?? 0,
			}));

			setOrders(orders_data);
			setPharmacyStats(transformedPharmacyStats);
			setStatusStats(status_stats);
		};

		fetchData();
	}, [startDate, endDate, selectedCity, selectedProvider, selectedMode]);

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				setIsCommandOpen((open) => !open);
			}
		};

		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	useEffect(() => {
		const cityParam = searchParams.get("city");
		const providerParam = searchParams.get("provider");

		if (cityParam) {
			setSelectedCity(cityParam);
		}
		if (providerParam) {
			setSelectedProvider(providerParam);
		}
	}, [searchParams]);

	const updateSearchParams = (key: string, value: string) => {
		const params = new URLSearchParams(searchParams);
		params.set(key, value);
		navigate(`/?${params.toString()}`);
	};

	const getDateRange = (period: string) => {
		const end = new Date();
		const start = new Date();

		switch (period) {
			case "1D":
				start.setDate(end.getDate() - 1);
				break;
			case "2D":
				start.setDate(end.getDate() - 2);
				break;
			case "5D+":
				start.setDate(end.getDate() - 5);
				break;
			default:
				return { start: "", end: "" };
		}

		return {
			start: start.toISOString().split("T")[0],
			end: end.toISOString().split("T")[0],
		};
	};

	const OrderLink = ({ count, period }: { count: number; period: string }) => {
		const { start, end } = getDateRange(period);
		if (!start || !end) return <span>{count}</span>;

		return (
			<Link
				to={`/orders?startDate=${start}&endDate=${end}`}
				className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
			>
				{count}
			</Link>
		);
	};

	const renderPharmacyTable = () => {
		const columns =
			selectedMode === "Hyperlocal"
				? [
						"10 mins",
						"11-20 mins",
						"21-30 mins",
						"31-40 mins",
						"41-50 mins",
						"51-60 mins",
					]
				: ["1D", "2D", "5D+"];

		return (
			<div className="overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
					<thead>
						<tr>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Pharmacy
							</th>
							{columns.map((col) => (
								<th
									key={col}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									{col}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
						{pharmacyStats.map((stat) => (
							<tr key={stat.pharmacy}>
								<td className="px-6 py-4 whitespace-nowrap">{stat.pharmacy}</td>
								{columns.map((col) => (
									<td key={col} className="px-6 py-4 whitespace-nowrap">
										{columns.includes(col) &&
										["1D", "2D", "5D+"].includes(col) ? (
											<OrderLink
												count={stat[col as keyof PharmacyStats] as number}
												period={col}
											/>
										) : (
											stat[col as keyof PharmacyStats]
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	};

	const renderStatusTable = () => (
		<div className="overflow-x-auto">
			<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
				<thead>
					<tr>
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Status
						</th>
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							1D
						</th>
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							2D
						</th>
						<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Total Orders
						</th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
					{statusStats.map((stat) => (
						<tr key={stat.status}>
							<td className="px-6 py-4 whitespace-nowrap">{stat.status}</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<OrderLink count={stat["1D"]} period="1D" />
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<OrderLink count={stat["2D"]} period="2D" />
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<Link
									to="/orders"
									className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
								>
									{stat.total_orders}
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);

	return (
		<div className="min-h-screen bg-white dark:bg-gray-900">
			<div className="p-6">
				<h1 className="text-2xl font-bold">Dashboard</h1>
				<div className="flex justify-between items-center mb-6">
					<div className="flex items-center space-x-4">
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<button
										className="flex items-center gap-2 px-3 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md dark:text-gray-300 border dark:border-gray-700"
										type="button"
										onClick={() => setIsCommandOpen(true)}
									>
										<Search size={16} />
										<span className="hidden sm:inline-flex items-center">
											Search
											<kbd className="ml-2 pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground">
												<span className="text-xs">⌘</span>K
											</kbd>
										</span>
									</button>
								</TooltipTrigger>
								<TooltipContent className="dark:bg-gray-800 dark:text-white">
									Search dashboard (⌘K)
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						<Filters
							selectedCity={selectedCity}
							setSelectedCity={setSelectedCity}
							selectedProvider={selectedProvider}
							setSelectedProvider={setSelectedProvider}
							selectedMode={selectedMode}
							setSelectedMode={setSelectedMode}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
						/>
					</div>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
					{orders.map((order) => (
						<StatusCard
							key={order.status}
							status={order.status}
							count={order.count}
							isSelected={statusFilter === order.status}
							onClick={() => {
								setStatusFilter(order.status);
								updateSearchParams("status", order.status);
								setCurrentPage(1);
							}}
						/>
					))}
				</div>

				<div className="space-y-6">
					<div>
						<h2 className="text-xl font-semibold mb-4">Pharmacy-wise Orders</h2>
						{renderPharmacyTable()}
					</div>

					<div>
						<h2 className="text-xl font-semibold mb-4">Status-wise Orders</h2>
						{renderStatusTable()}
					</div>
				</div>
			</div>
			<CommandDialog open={isCommandOpen} onOpenChange={setIsCommandOpen}>
				<CommandInput placeholder="Type a command or search..." />
				<CommandList>
					<CommandEmpty>No results found.</CommandEmpty>
					<CommandGroup heading="Cities">
						<CommandItem
							onSelect={() => {
								setSelectedCity("hyderabad");
								updateSearchParams("city", "hyderabad");
								setIsCommandOpen(false);
							}}
						>
							Show Hyderabad Orders
						</CommandItem>
						<CommandItem
							onSelect={() => {
								setSelectedCity("chennai");
								updateSearchParams("city", "chennai");
								setIsCommandOpen(false);
							}}
						>
							Show Chennai Orders
						</CommandItem>
						<CommandItem
							onSelect={() => {
								setSelectedCity("kolkata");
								updateSearchParams("city", "kolkata");
								setIsCommandOpen(false);
							}}
						>
							Show Kolkata Orders
						</CommandItem>
					</CommandGroup>
					<CommandGroup heading="Providers">
						<CommandItem
							onSelect={() => {
								setSelectedProvider("medibuddy");
								updateSearchParams("provider", "medibuddy");
								setIsCommandOpen(false);
							}}
						>
							Show MediBuddy Orders
						</CommandItem>
						<CommandItem
							onSelect={() => {
								setSelectedProvider("pazcare");
								updateSearchParams("provider", "pazcare");
								setIsCommandOpen(false);
							}}
						>
							Show PazCare Orders
						</CommandItem>
					</CommandGroup>
				</CommandList>
			</CommandDialog>
		</div>
	);
};
