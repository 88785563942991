import { LogOutIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase"; // Adjust the import path as needed

export const LogoutButton = ({ size }: { size: number }) => {
	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) throw error;
			navigate("/login");
		} catch (error) {
			console.error("Error logging out:", (error as Error).message);
		}
	};
	return <LogOutIcon onClick={handleLogout} size={size} />;
};
