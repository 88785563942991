import mixpanel from "mixpanel-browser";

// Initialize Mixpanel analytics
const MIXPANEL_TOKEN = "929d80c5b84cbd0a782ad227155c91d4";

// Initialize Mixpanel analytics on app startup
export function initAnalytics() {
	mixpanel.init(MIXPANEL_TOKEN, {
		debug: true,
		track_pageview: true,
		persistence: "localStorage",
	});
	return mixpanel;
}
