import type React from "react";
import { trackOrderStatus } from "../../lib/constants";
import type { Order, OrderHistoryType } from "../../lib/types";
import { formatDate } from "../../lib/utils";

export const OrderHistory: React.FC<{ order: Order }> = ({ order }) => {
  const requiredStatuses = trackOrderStatus;
  const sortedHistory = order.order_history.sort(
    (a: OrderHistoryType, b: OrderHistoryType) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  return (
    <div className="relative p-3">
      <div className="space-y-4">
        {requiredStatuses.map(status => {
          const historyItem = sortedHistory.find(
            (history: OrderHistoryType) => history.status === status,
          );
          const isCompleted = !!historyItem;

          return (
            <div key={status} className="relative flex items-start">
              <div className="absolute flex items-center justify-center">
                <div
                  className={`w-3 h-3 rounded-full border-2 ${
                    isCompleted
                      ? "bg-gray-500 border-gray-500"
                      : "bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-500"
                  }`}
                />
              </div>
              <div className="ml-10 flex-grow">
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <h4 className="text-gray-900 dark:text-gray-100 font-medium">
                      {status}
                    </h4>
                  </div>
                  <span className="text-gray-500 dark:text-gray-400">
                    {historyItem
                      ? formatDate(new Date(historyItem.created_at))
                      : "Pending"}
                  </span>
                </div>
              </div>
              {status !== "Delivered" && (
                <div
                  className={`absolute left-1 top-3.5 h-full w-0.5 ${
                    isCompleted
                      ? "bg-gray-500"
                      : "border-dotted bg-gray-200 dark:bg-gray-700 border-gray-300 dark:border-gray-500"
                  }`}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
