import type React from "react";
import { useEffect, useState } from "react";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../components/ui/select";
import type { Pharmacy } from "../../lib/types";
import { mockDataService } from "../../services/mockDataService";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { PharmacyLeaderboard } from "./PharmacyLeaderboard";

const StatBar = ({
	value,
	label,
	color,
}: { value: number; label: string; color: string }) => (
	<div className="mb-2">
		<div className="flex justify-between text-sm mb-1 text-foreground">
			<span>{label}</span>
			<span>{value.toFixed(0)}%</span>
		</div>
		<div className="w-full bg-muted rounded-full h-2">
			<div
				className={`h-2 rounded-full ${color}`}
				style={{ width: `${value}%` }}
			/>
		</div>
	</div>
);

const PharmacyCard = ({ pharmacy }: { pharmacy: Pharmacy }) => (
	<div className="border rounded-lg p-4 bg-background shadow-sm">
		<div className="flex justify-between items-start mb-4">
			<h3 className="font-semibold text-lg text-foreground">{pharmacy.name}</h3>
			{pharmacy.distance !== undefined && (
				<span className="text-sm text-muted-foreground">
					{pharmacy.distance.toFixed(1)} km away
				</span>
			)}
		</div>
		<StatBar
			value={pharmacy.fulfillmentPercentage}
			label="Fulfillment"
			color="bg-blue-500"
		/>
		<StatBar
			value={pharmacy.fulfillmentSpeed}
			label="Speed"
			color="bg-purple-500"
		/>
		<StatBar value={pharmacy.slaAdherence} label="SLA" color="bg-green-500" />
		<StatBar
			value={(pharmacy.currentBills / pharmacy.maxBills) * 100}
			label="Load"
			color="bg-yellow-500"
		/>
		<div className="mt-4 text-sm text-muted-foreground">
			<div className="flex justify-between mb-1">
				<span>Operating Hours:</span>
				<span>
					{pharmacy.remainingOperatingHours}/{pharmacy.totalOperatingHours}h
				</span>
			</div>
			<div className="flex justify-between">
				<span>Procurement Time:</span>
				<span>{pharmacy.procurementTime}h</span>
			</div>
		</div>
	</div>
);

export const PharmacyStats: React.FC = () => {
	const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
	const [filteredPharmacies, setFilteredPharmacies] = useState<Pharmacy[]>([]);
	const [selectedPharmacyId, setSelectedPharmacyId] = useState<string>("");
	const [open, setOpen] = useState(false);

	useEffect(() => {
		mockDataService.getPharmacies().then((data) => {
			setPharmacies(data);
			setFilteredPharmacies(data);
		});
	}, []);

	const selectedPharmacy = pharmacies.find((p) => p.id === selectedPharmacyId);

	const handlePharmacySelect = (currentValue: string) => {
		const pharmacy = pharmacies.find(
			(p) => p.name.toLowerCase() === currentValue.toLowerCase(),
		);

		if (pharmacy) {
			setSelectedPharmacyId(pharmacy.id);
			setOpen(false);

			const pharmaciesWithDistance = pharmacies
				.map((p) => ({
					...p,
					distance: mockDataService.calculateDistance(
						pharmacy.location.latitude,
						pharmacy.location.longitude,
						p.location.latitude,
						p.location.longitude,
					),
				}))
				.sort((a, b) => (a.distance || 0) - (b.distance || 0));

			setFilteredPharmacies(pharmaciesWithDistance);
		}
	};

	const filterPharmaciesByDate = (startDate: string, endDate: string) => {
		const filtered = pharmacies.filter((pharmacy) => {
			return true;
		});
		setFilteredPharmacies(filtered);
	};

	return (
		<div className="container mx-auto px-4 space-y-6">
			<Tabs defaultValue="leaderboard">
				<TabsList className="grid w-full max-w-[400px] grid-cols-2">
					<TabsTrigger value="leaderboard">Leaderboard</TabsTrigger>
					<TabsTrigger value="ranking">Ranking</TabsTrigger>
				</TabsList>

				<TabsContent value="leaderboard" className="mt-6">
					<PharmacyLeaderboard
						pharmacies={filteredPharmacies}
						onDateRangeChange={filterPharmaciesByDate}
					/>
				</TabsContent>

				<TabsContent value="ranking" className="mt-6">
					<div className="w-full max-w-xs mx-auto sm:mx-0">
						<Select onValueChange={handlePharmacySelect}>
							<SelectTrigger className="w-full">
								<SelectValue placeholder="Select pharmacy..." />
							</SelectTrigger>
							<SelectContent>
								{pharmacies.map((pharmacy) => (
									<SelectItem key={pharmacy.id} value={pharmacy.name}>
										{pharmacy.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>

					{!selectedPharmacy && (
						<div className="text-center text-muted-foreground mt-8">
							Select a pharmacy to view its details and nearby locations
						</div>
					)}

					{selectedPharmacy && (
						<div className="space-y-8">
							{/* Selected Pharmacy Section */}
							<section>
								<h2 className="text-xl font-bold mb-4 px-2 text-foreground">
									Selected Pharmacy
								</h2>
								<div className="w-full">
									<PharmacyCard pharmacy={selectedPharmacy} />
								</div>
							</section>

							{/* Nearby Pharmacies Section */}
							<section>
								<h2 className="text-xl font-bold mb-4 px-2 text-foreground">
									Nearby Pharmacies
								</h2>
								<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
									{pharmacies
										.filter((p) => p.id !== selectedPharmacy.id)
										.slice(0, 3)
										.map((pharmacy) => (
											<PharmacyCard key={pharmacy.id} pharmacy={pharmacy} />
										))}
								</div>
							</section>
						</div>
					)}
				</TabsContent>
			</Tabs>
		</div>
	);
};
