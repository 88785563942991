import type React from "react";
import { Card } from "./card";

interface StatusCardProps {
	status: string;
	count: string | number;
	isSelected: boolean;
	onClick: () => void;
}

export const StatusCard: React.FC<StatusCardProps> = ({
	status,
	count,
	isSelected,
	onClick,
}) => {
	return (
		<Card
			className={`p-4 cursor-pointer dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
        ${
					isSelected
						? "ring-2 ring-indigo-500 dark:ring-indigo-400"
						: "hover:border-gray-300 dark:hover:border-gray-600"
				}`}
			onClick={onClick}
		>
			<h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
				{status}
			</h3>
			<p className="text-2xl font-bold dark:text-white">{count}</p>
		</Card>
	);
};
