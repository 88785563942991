import {
	type ColumnDef,
	type SortingState,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useMemo, useState } from "react";
import type { Pharmacy } from "../../lib/types";
import { Button } from "../ui/button";
import { DateInput } from "../ui/dateInput";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";

interface LeaderboardMetrics {
	pharmacy: Pharmacy;
	score: number;
	rank: number;
}

const calculateScore = (pharmacy: Pharmacy): number => {
	const weights = {
		fulfillment: 0.3,
		speed: 0.25,
		sla: 0.25,
		load: 0.2,
	};

	return (
		pharmacy.fulfillmentPercentage * weights.fulfillment +
		pharmacy.fulfillmentSpeed * weights.speed +
		pharmacy.slaAdherence * weights.sla +
		(1 - pharmacy.currentBills / pharmacy.maxBills) * 100 * weights.load
	);
};

const QUICK_RANGES = [
	{ label: "Today", days: 0 },
	{ label: "Yesterday", days: 1 },
	{ label: "Last 3 days", days: 3 },
	{ label: "Last 7 days", days: 7 },
	{ label: "Last 30 days", days: 30 },
] as const;

interface PharmacyLeaderboardProps {
	pharmacies: Pharmacy[];
	onDateRangeChange: (startDate: string, endDate: string) => void;
}

export function PharmacyLeaderboard({
	pharmacies,
	onDateRangeChange,
}: PharmacyLeaderboardProps) {
	const [sorting, setSorting] = useState<SortingState>([
		{ id: "score", desc: true },
	]);

	const today = new Date();
	const [startDate, setStartDate] = useState<string>(
		today.toISOString().split("T")[0],
	);
	const [endDate, setEndDate] = useState<string>(
		today.toISOString().split("T")[0],
	);

	const setDateRange = (days: number) => {
		const end = new Date();
		const start = new Date();
		start.setDate(end.getDate() - days);

		const newStartDate = start.toISOString().split("T")[0];
		const newEndDate = end.toISOString().split("T")[0];

		setStartDate(newStartDate);
		setEndDate(newEndDate);
		onDateRangeChange(newStartDate, newEndDate);
	};

	const leaderboardData: LeaderboardMetrics[] = useMemo(() => {
		const data = pharmacies.map((pharmacy) => ({
			pharmacy,
			score: calculateScore(pharmacy),
			rank: 0,
		}));

		const sortedData = [...data].sort((a, b) => b.score - a.score);
		return sortedData.map((item, index) => ({
			...item,
			rank: index + 1,
		}));
	}, [pharmacies]);

	const columns: ColumnDef<LeaderboardMetrics>[] = [
		{
			accessorKey: "rank",
			header: "Rank",
			cell: ({ row }) => (
				<div className="font-semibold">{row.original.rank}</div>
			),
			enableSorting: false,
		},
		{
			accessorKey: "pharmacy.name",
			header: "Pharmacy",
			cell: ({ row }) => (
				<div className="font-medium">{row.original.pharmacy.name}</div>
			),
		},
		{
			accessorKey: "score",
			header: "Score",
			cell: ({ row }) => <div>{row.original.score.toFixed(1)}</div>,
		},
		{
			accessorKey: "pharmacy.procurementTime",
			header: "TAT (hours)",
			cell: ({ row }) => <div>{row.original.pharmacy.procurementTime}h</div>,
		},
		{
			accessorKey: "pharmacy.fulfillmentPercentage",
			header: "Fulfillment",
			cell: ({ row }) => (
				<div>{row.original.pharmacy.fulfillmentPercentage.toFixed(1)}%</div>
			),
		},
		{
			accessorKey: "pharmacy.fulfillmentSpeed",
			header: "Speed",
			cell: ({ row }) => (
				<div>{row.original.pharmacy.fulfillmentSpeed.toFixed(1)}%</div>
			),
		},
		{
			accessorKey: "pharmacy.slaAdherence",
			header: "SLA",
			cell: ({ row }) => (
				<div>{row.original.pharmacy.slaAdherence.toFixed(1)}%</div>
			),
		},
	];

	const table = useReactTable({
		data: leaderboardData,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	const getSortIcon = (column: any) => {
		const sortDirection = column.getIsSorted();
		if (!sortDirection) return null;

		return sortDirection === "asc" ? (
			<ChevronUp className="h-4 w-4" />
		) : (
			<ChevronDown className="h-4 w-4" />
		);
	};

	return (
		<div className="space-y-4">
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-2">
					<DateInput
						value={startDate}
						onChange={(date) => {
							setStartDate(date);
							onDateRangeChange(date, endDate);
						}}
					/>
					<span className="text-muted-foreground">-</span>
					<DateInput
						value={endDate}
						onChange={(date) => {
							setEndDate(date);
							onDateRangeChange(startDate, date);
						}}
					/>
				</div>
				<div className="flex items-center space-x-2">
					<Select onValueChange={(value) => setDateRange(Number(value))}>
						<SelectTrigger className="w-[180px]">
							<SelectValue placeholder="Quick select" />
						</SelectTrigger>
						<SelectContent>
							{QUICK_RANGES.map((range) => (
								<SelectItem key={range.days} value={range.days.toString()}>
									{range.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<Button
						variant="outline"
						onClick={() => {
							setStartDate(today.toISOString().split("T")[0]);
							setEndDate(today.toISOString().split("T")[0]);
						}}
					>
						Reset
					</Button>
				</div>
			</div>

			<div className="rounded-md border border-border">
				<table className="w-full">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr
								key={headerGroup.id}
								className="border-b border-border bg-muted/50"
							>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										className="px-4 py-3 text-left text-sm font-semibold text-foreground"
									>
										{header.isPlaceholder ? null : (
											<div
												className={`flex items-center gap-2 ${
													header.column.getCanSort()
														? "cursor-pointer select-none hover:text-foreground/70"
														: ""
												}`}
												onClick={header.column.getToggleSortingHandler()}
											>
												{flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
												{header.column.getCanSort() &&
													getSortIcon(header.column)}
											</div>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr
								key={row.id}
								className="border-b border-border transition-colors hover:bg-muted/50"
							>
								{row.getVisibleCells().map((cell) => (
									<td
										key={cell.id}
										className="px-4 py-3 text-sm text-foreground"
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
