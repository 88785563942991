import * as React from "react";
import { cn } from "../../lib/utils";

export const SidebarContext = React.createContext<{
	expanded: boolean;
	setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export function SidebarProvider({
	children,
	defaultOpen = true,
}: {
	children: React.ReactNode;
	defaultOpen?: boolean;
}) {
	const [expanded, setExpanded] = React.useState(defaultOpen);

	return (
		<SidebarContext.Provider value={{ expanded, setExpanded }}>
			{children}
		</SidebarContext.Provider>
	);
}

export function Sidebar({
	className,
	children,
	collapsible = "icon",
	side = "left",
}: {
	className?: string;
	children: React.ReactNode;
	collapsible?: "icon" | "full" | false;
	side?: "left" | "right";
}) {
	const { expanded } = React.useContext(SidebarContext) || {};

	return (
		<aside
			className={cn(
				"h-screen",
				expanded ? "w-64" : "w-16",
				side === "left" ? "border-r" : "border-l",
				"transition-all duration-300",
				className,
			)}
		>
			{children}
		</aside>
	);
}

export function SidebarHeader({
	children,
	className,
}: { children: React.ReactNode; className?: string }) {
	const { expanded } = React.useContext(SidebarContext) || {};

	return (
		<div
			className={cn("p-4", expanded ? "text-left" : "text-center", className)}
		>
			{children}
		</div>
	);
}

export function SidebarContent({ children }: { children: React.ReactNode }) {
	return <div className="px-3 py-2">{children}</div>;
}

export function SidebarMenu({ children }: { children: React.ReactNode }) {
	return <nav className="space-y-2">{children}</nav>;
}

export function SidebarMenuItem({
	children,
	className,
	title,
}: {
	children: React.ReactNode;
	title: string;
	className?: string;
}) {
	return (
		<div title={title} className={className}>
			{children}
		</div>
	);
}

export function SidebarMenuButton({
	children,
	className,
	icon,
	...props
}: React.ComponentPropsWithoutRef<"button"> & { icon: React.ReactNode }) {
	const { expanded } = React.useContext(SidebarContext) || {};

	return (
		<button
			className={cn(
				"flex items-center w-full p-2 rounded-lg hover:bg-gray-100 transition-colors",
				expanded ? "justify-start space-x-3" : "justify-center",
				className,
			)}
			{...props}
		>
			{icon}
			{expanded && <span>{children}</span>}
		</button>
	);
}

export function SidebarTrigger({ className }: { className?: string }) {
	const { expanded, setExpanded } = React.useContext(SidebarContext) || {};

	if (setExpanded === undefined) return null;

	return (
		<button
			type="button"
			onClick={() => setExpanded((prev) => !prev)}
			className={cn(
				"fixed bottom-4 left-4 p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors",
				className,
			)}
		>
			{expanded ? "←" : "→"}
		</button>
	);
}
